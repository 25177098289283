/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import logo from './logoRoomy.png';
import axios from 'axios';
import appstore from './appstore.png';
import QRCode from 'react-qr-code';

import './App.css';

const isIOS = (function () {
  const iosQuirkPresent = function () {
    const audio = new Audio();
    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

function App() {
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [key, setKey] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  // const getIdApi = async (id) => {
  //   // twxitter://user?username=owendunnigan ()
  //   const response = await fetch(`http://app.localhost/api/v1/file/${id}`, {
  //     // mode: 'cors',
  //     headers: {
  //       'x-api-key': process.env.REACT_APP_API_KEY,
  //       // 'Content-Type': 'application/json',
  //     },
  //   });
  //   if (response.status === 200) {
  //     return response.json();
  //   }
  //   throw response.json();
  // };

  useEffect(() => {
    setLoader(true);
    // const pathId = window.location.pathname.substring(1);

    const getQueryParams = (query = null) =>
      (query || window.location.search.replace('?', ''))
        .split('&')
        .map((e) => e.split('=').map(decodeURIComponent))
        .reduce((r, [k, v]) => ((r[k] = v), r), {});
    const pathId = getQueryParams().id;
    if (pathId) {
      axios(`${process.env.REACT_APP_BACK_URL}/v1/file/${pathId}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          // 'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          setKey(res.data.data.encrypted);
          if (isIOS) {
            window.location = `roomance://${res.data.data.encrypted}`;
          }
        })
        .catch((er) => {
          setError(true);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setError(true);
      setLoader(false);
    }

    // window.location = "twitter ://user?username=owendunnigan";
    // window.open('twitter://test-deeplink', '_blank');
  }, []);

  const clickApp = () => {
    // if (isIOS) {
    window.location = `roomance://${key}`;
    // }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  const openAppStore = () => {
    window.location =
      'https://apps.apple.com/app/roomy-ai-home-decor-design/id6447260377';
  };

  return (
    <div className="App">
      {loader && (
        <div className="container-loader">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <header className="App-header">
        <img src={logo} alt="logo" />
        <span className="logo-text">Roomy</span>
      </header>
      {isMobile ? (
        <main>
          {!loader && error ? (
            <div className="text-error">
              An error occurred, please try again later!
            </div>
          ) : (
            <div className="btn-container">
              <div onClick={clickApp} className="button-60" role="button">
                You can open scan from mobile app!
              </div>
            </div>
          )}

          <div onClick={openAppStore} className="downoload-appstore">
            <img className="icon-appstore" src={appstore} alt="app store" />
            Install the Roomy app to open the shared scan of a room
          </div>
        </main>
      ) : (
        <main>
          <div className="qrcode">
            <h2>Use QR code below to install the Roomy app!</h2>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={window.location.href}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div onClick={openAppStore} className="downoload-appstore">
            <img className="icon-appstore" src={appstore} alt="app store" />
            Downoload app from AppStore
          </div>
        </main>
      )}
    </div>
  );
}

export default App;
